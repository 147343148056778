import React, { useState, useCallback } from 'react'
import axios from 'axios'
import { FaSpinner } from 'react-icons/fa'

const PROGRESS_LIMIT_COUNT = 10;
const PROGRESS_LIMIT_MS = 3000;  // 3 seconds
const UNIT_LIST = ['', 'KB', 'MB', 'GB', 'TB', 'PB']

function humanReadableBytes(bytes: number): string {
  const index = Math.max(0, Math.floor(Math.log(bytes) / Math.log(1024))) || 0
  const unit = UNIT_LIST[index]
  const size = bytes / Math.pow(1024, index)

  return `${size.toFixed(1)} ${unit}`
}

function getSpeedDiff(start: ProgressFrame, end: ProgressFrame): number {
  return ((start.loaded - end.loaded) / (start.time - end.time)) * 1000
}

function getTotalSpeed(frames: ProgressFrame[]): number {
  return getSpeedDiff(frames[0], frames[frames.length - 1])
}

function getPeakSpeed(frames: ProgressFrame[]): number {
  return frames.reduce((previous, start, index) => {
    const end: ProgressFrame | undefined = frames[index + 1];
    if (!end) return previous

    return Math.max(getSpeedDiff(start, end), previous)
  }, 0)
}

const SpeedTest: React.FC<SpeedTestProps> = ({ url }) => {
  const [speedState, setSpeedState] = useState(0)
  const [peakState, setPeakState] = useState(0)
  const [latencyState, setLatencyState] = useState(0)
  const [status, setStatus] = useState<Status>('idle')

  const handleClick = useCallback(async (): Promise<void> => {
    setStatus('loading')
    const timeStart = Date.now()
    const testUrl = `${url}?${timeStart}`
    const progress: ProgressFrame[] = [
      {
        time: 0,
        loaded: 0,
        percent: 0,
      },
    ]

    const source = axios.CancelToken.source();

    try {
      await axios.get(testUrl, {
        cancelToken: source.token,
        onDownloadProgress: (progressEvent: AxiosProgressEvent) => {
          const timeNow = Date.now()
          const timeDuration = timeNow - timeStart
          const percent = (progressEvent.loaded / progressEvent.total) * 100
          progress.push({
            time: timeDuration,
            loaded: progressEvent.loaded,
            percent: percent,
          })

          // Cancel if we have enough data and time has passed
          if (progress.length > PROGRESS_LIMIT_COUNT && timeNow - timeStart > PROGRESS_LIMIT_MS) {
            source.cancel();
          }
        },
      }).catch((error: Error) => {
        // Should we have enough data, move along as a success
        if (progress.length >= PROGRESS_LIMIT_COUNT) return

        // Otherwise forward the rejection
        return Promise.reject(error)
      })

      setSpeedState(getTotalSpeed(progress));
      setPeakState(getPeakSpeed(progress));
      setLatencyState(progress[1].time - progress[0].time);
      setStatus('complete')
    } catch (error) {
      setStatus('error')
    }
  }, [url])

  if (status === 'error') {
    return (
      <div className="text-white mb-8">
        <div className="text-white font-semibold uppercase text-lg">
          Something has gone wrong, please try again.
        </div>
        <Trigger triggerTest={handleClick} />
      </div>
    )
  }
  if (status === 'loading') {
    return (
      <div className="flex justify-center items-center text-white font-xl">
        <span className="mr-2">Testing</span>{' '}
        <FaSpinner className="animate-spin" />
      </div>
    )
  }
  if (status === 'complete') {
    return (
      <>
        <div className="text-white mb-8">
          <div className="text-white font-semibold uppercase text-lg">
            Results:
          </div>
          <div>
            {`Speed: ${humanReadableBytes(speedState)}/sec`}
          </div>
          <div>
            {`Peak: ${humanReadableBytes(peakState)}/sec`}
          </div>
          <div>
            {`Latency: ${latencyState} msec`}
          </div>
        </div>
        <Trigger triggerTest={handleClick} />
      </>
    )
  }
  return <Trigger triggerTest={handleClick} />
}
const Trigger: React.FC<TriggerProps> = ({ triggerTest }) => {
  return (
    <button
      className="btn btn-primary font-semibold mb-6"
      onClick={triggerTest}
    >
      Test Speed
    </button>
  )
}
type SpeedTestProps = { url: string }
type TriggerProps = { triggerTest: () => void }
type Status = 'idle' | 'loading' | 'error' | 'complete'
interface AxiosProgressEvent {
  loaded: number;
  total: number;
}
interface ProgressFrame {
  time: number;
  loaded: number;
  percent: number;
}

export default SpeedTest
import { graphql, Link } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";
import React from 'react'

import SpeedTest from '../../../components/data-centers/speed-test'
import Layout from '../../../components/layout'
import Seo from '../../../components/Seo';
import StructuredData from '../../../components/structured-data'

type Props = {
  location: Record<string, unknown>
  data: {
    hero: { childImageSharp: { fluid: FluidObject } }
    region: { childImageSharp: { fluid: FluidObject } }
    phxCenter: { childImageSharp: { fluid: FluidObject } }
    wpPage: {
      uri: string
      seo: Record<string, unknown>
    }
  }
};

const url = 'https://phoenix-test.liquidweb.com/100mb.bin'

const PageUSWest: React.FC<Props> = ({ data }) => {
  const { hero, region, phxCenter, wpPage } = data

  return (
    <Layout>
      <Seo post={wpPage} />
      <StructuredData pageType="webPage" />
      <div className="py-10 px-5 lg:py-20 relative text-white">
        <div className="relative z-30 container">
          <h1 className="text-white mb-4">US-West Region</h1>
          <h2 role="doc-subtitle" className="font-semibold">
            Home to Our Arizona &amp; Texas Facilities
          </h2>
        </div>
        <div
          className="absolute w-full h-full top-0 left-0 z-20"
          style={{
            backgroundColor: `rgba(46,60,75,0.93)`,
          }}
          role="presentation"
        ></div>
        <GatsbyImage
          image={hero.childImageSharp.gatsbyImageData}
          alt="US-West Region"
          className="w-full h-full left-0 top-0 z-10 data-center-bg"
          style={{ position: `absolute` }} />
      </div>
      <div className="bg-lw-lt-gray">
        <div className="px-8 container py-8 lg:py-20 grid grid-cols-5 gap-10">
          <div className="col-span-5 lg:col-span-3">
            <h2 className="mb-6">Overview</h2>
            <div className="mb-2">
              Our US-West region in Phoenix, Arizona provides a distinct set of
              advantages desired by some of our most savvy customers. This
              Western US location provides geographic redundancy needed for
              disaster recovery solutions. Not to mention the location provides
              a connection to tier 1 network providers.
            </div>
            <div>
              This location also provides an enhanced performance for users on
              the west coast. By using this location as a Hot Standby site
              allows your infrastructure to fail gracefully if issues arise.
            </div>
          </div>
          <div className="col-span-5 lg:col-span-2">
            <GatsbyImage image={region.childImageSharp.gatsbyImageData} alt="US-West Region" />
          </div>
        </div>
      </div>
      <div className="px-8 py-8 lg:py-16 container">
        <h2 className="mb-6">US-West Region</h2>
        <div className="grid grid-cols-2 gap-4 mb-8">
          <div className="col-span-2 lg:col-span-1">
            <div>
              <span className="font-bold">Location:</span> Phoenix, Arizona
            </div>
            <div>
              <span className="font-bold">Data Centers:</span> 1
            </div>
            <div>
              <span className="font-bold">Providers:</span> Level 3, Cogent
            </div>
          </div>
          <div className="col-span-2 lg:col-span-1">
            <div>
              <span className="font-bold">Established:</span> 2011
            </div>
            <div>
              <span className="font-bold">Zones:</span> 1
            </div>
            <div>
              <span className="font-bold">Offices:</span> 2
            </div>
          </div>
        </div>
        <h4 className="pb-6 text-xl lg:text-2xl">
          Data Centers in This Region
        </h4>
        <div className="grid grid-cols-4 gap-10">
          <div className="col-span-4 lg:col-span-1">
            <GatsbyImage
              image={phxCenter.childImageSharp.gatsbyImageData}
              alt="Phoenix Data Center" />
          </div>
          <div className="col-span-4 lg:col-span-1">
            <div className="font-bold">Phoenix Data Center</div>
            <div>Capacity: 2,000 Servers</div>
            <div>SSAE-16 Compliant</div>
            <div>HIPAA Compliant</div>
          </div>
        </div>
      </div>
      <div className="bg-lw-purple">
        <div className="px-8 py-8 container text-center text-white">
          <div className="text-lg uppercase font-semibold mb-4">
            Curious how our network performs?
          </div>
          <div className="text-2xl font-semibold mb-8">
            Test The Speed of Our US-West Region Now!
          </div>
          <SpeedTest url={url} />
          <div className="text-xs">
            Note: Speeds are limited by your local device&apos;s connection. Users on
            a mobile device using 3G will see significantly slower speeds than
            those hardwired to a DSL/Cable Modem.
          </div>
        </div>
      </div>
      <div className="px-8 py-8 lg:py-20 grid grid-cols-2 container gap-10">
        <div className="col-span-2 lg:col-span-1">
          <div className="text-lg font-semibold mb-1">Power System</div>
          <div className="border-t-2 border-lw-blue max-w-33 m-0" />
          <ul className="mt-1 list-disc pl-10 mt-5">
            <li>Medium voltage distribution</li>
            <li>Four 10 megawatt feeds available</li>
            <li>Diverse paths from substation</li>
            <li>N+1 Cummins backup generator</li>
            <li>2N power available</li>
            <li>Critical load on S&amp;C PureWave® UPS systems</li>
            <li>200+ watts per sq. ft power densities</li>
          </ul>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <div className="text-lg font-semibold mb-1">Network Gear</div>
          <div className="border-t-2 border-lw-blue max-w-33 m-0" />
          <ul className="mt-1 list-disc pl-10 mt-5">
            <li>Diverse paths into building</li>
            <li>Dedicated meet-me room</li>
            <li>Bandwidth co-op solutions</li>
            <li>Carrier neutral</li>
            <li>On-net transport to most major global cities</li>
          </ul>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <div className="text-lg font-semibold mb-1">Cooling</div>
          <div className="border-t-2 border-lw-blue max-w-33 m-0" />
          <ul className="mt-1 list-disc pl-10 mt-5">
            <li>N+1 modular chilled water plant</li>
            <li>N+2 Stulz-ATS CRAH units</li>
          </ul>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <div className="text-lg font-semibold mb-1">Security</div>
          <div className="border-t-2 border-lw-blue max-w-33 m-0" />
          <ul className="mt-1 list-disc pl-10 mt-5">
            <li>24/7/365 Manned Facilities</li>
            <li>
              Multiple closed circuit TV security cameras, covering all
              entrances and datacenter space
            </li>
            <li>
              Site entrance controlled by electronic perimeter access card
              system
            </li>
            <li>Carrier neutral</li>
            <li>Site remotely monitored by 3rd party security company</li>
          </ul>
        </div>
      </div>
      <div className="p-8 container">
        <Link to="/data-centers" aria-label="Back to Data Centers">
          &#x3c; Back to Data Centers
        </Link>
      </div>
    </Layout>
  );
}

export default PageUSWest

export const query = graphql`query USWestPage {
  wpPage(slug: {eq: "us-west"}) {
    uri
    chatDepartment
    seo {
      ...postSeoFields
    }
  }
  hero: file(relativePath: {eq: "about-us/data-centers/traffic-spikes.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
    }
  }
  region: file(
    relativePath: {eq: "about-us/data-centers/data-center-us-west.png"}
  ) {
    childImageSharp {
      gatsbyImageData(
        quality: 100
        width: 500
        placeholder: TRACED_SVG
        layout: CONSTRAINED
      )
    }
  }
  phxCenter: file(relativePath: {eq: "about-us/data-centers/phx-front.jpg"}) {
    childImageSharp {
      gatsbyImageData(
        quality: 100
        width: 400
        placeholder: TRACED_SVG
        layout: CONSTRAINED
      )
    }
  }
}
`
